import React from "react";
import { ReactComponent as Logo } from '../assets/Novomatic-logo.svg';
import { ReactComponent as Logo2 } from '../assets/N-shape.svg';
import logo3 from '../assets/logo-3.webp';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <Logo2 className="n-logo-footer"/>
          <div className="footer-links">
            <div className="logo2 text-center mt-3">
              <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                <Logo/>
              </Link>
            </div>
          </div>
          <div className="row footer-navbar justify-content-center mt-1">
            <div className="col-sm-12 col-md-12 col-lg-auto ms-sm-0 me-lg-0 container mt-2 mt-sm-3 mt-lg-1 pe-0 g-0">
              <div className="row justify-content-center g-0">
                <Link className="col-auto footer-nav-link px-2" to={`${process.env.PUBLIC_URL + "/o-nama"}`}>O nama</Link>
                <Link className="col-auto footer-nav-link nb px-2" to={`${process.env.PUBLIC_URL + "/automati"}`}>automati</Link>
                <Link className="col-auto footer-nav-link px-2" to={`${process.env.PUBLIC_URL + "/igre"}`}>igre</Link>
                <Link className="col-auto footer-nav-link px-2" to={`${process.env.PUBLIC_URL + "/kontakt"}`}>kontakt</Link>
                <Link reloadDocument className="col-auto footer-nav-link px-2" to={`${process.env.PUBLIC_URL + "/documents/OUP.pdf"}`}>OUP</Link>
              </div>
            </div>
          </div>
        <div>
          <div className="row align-items-center justify-content-center mt-sm-1 g-0">
            <div className="footer-social w-100 col-12 col-md-4 p-0 align-items-center justify-content-center pe-3">
              <ul className="m-0 p-0 text-center mt-2">
                <li className="col-12 col-sm-auto d-none d-lg-inline">
                <a target="_blank" href="https://admiralclub.rs/" rel="noreferrer">
                  <img className="admiral-logo pe-xl-5" src={logo3} alt=""></img>
                </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/NOVOMATICrs" rel="noreferrer">
                    <i className="fa fa-facebook" ></i>              
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/novoinvestment/" rel="noreferrer">
                    <i className="fa fa-instagram "></i>              
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/novomatic_games?lang=en" rel="noreferrer">
                    <i className="fa fa-twitter"></i>              
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/novomatic-srbija/" rel="noreferrer">
                    <i className="fa fa-linkedin"></i>              
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.youtube.com/@NOVOMATIC-AG" rel="noreferrer">
                    <i className="fa fa-youtube-play"></i>
                  </a>
                </li>
                <li className="col-12 col-sm-auto d-none d-lg-inline">
                  <a target="_blank" href="https://admiralbet.rs/" rel="noreferrer">
                    <img className="admiralbet-logo ps-xl-5" src='/images/logo/admiralbet-logo.svg' alt=""></img>
                  </a>
                </li>
              </ul>
            </div>
            <div className="row justify-content-center mt-3 d-lg-none">
              <div className="col-6 text-end">
                <a target="_blank" href="https://admiralbet.rs/" rel="noreferrer">
                  <img className="admiralbet-logo ps-xl-5" src='/images/logo/admiralbet-logo.svg' alt=""></img>
                </a>
              </div>

              <div className="col-6 text-start">
                <a target="_blank" href="https://admiralclub.rs/" rel="noreferrer">
                  <img className="admiral-logo pe-xl-5" src={logo3} alt=""></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
          Copyright © NOVO INVESTMENT SRBIJA - 2023 - All Rights Reserved
      </div>
    </>
  );
};

export default Footer;