export const createCabinetsApi = (fetchApi) => {

  // function getCabinets() {
  //   return fetchApi(`/p/cabinets`, 'GET');
  // }

  // function getCabinetById(id) {
  //   return fetchApi(`/p/cabinet/${id}`, 'GET');
  // }
  function getCabinets() {
    return fetchApi(`/cabinets`, 'GET');
  }

  function getCabinetById(id) {
    return fetchApi(`/cabinet/${id}`, 'GET');
  }

  return {
    getCabinets,
    getCabinetById
  };
};