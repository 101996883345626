import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const ProductItem = ({ data, route, cabinetsAnimation, type }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route + data.id)
  }

  const handleScroll = () => {
    setTimeout(() => {
      var rect = document.querySelector(`.item${data.id}`).getBoundingClientRect();
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      if (!(rect.bottom < 0 || rect.top - viewHeight >= 0)) {
        cabinetsAnimation();
      }
    }, 300);
  }

  const handleResize = () => {
    const cabinetcontainer = document.querySelector(".cabinet-row-container");
    if(!cabinetcontainer) return;
    const productItem = document.querySelectorAll('.cabinet-row-container .product_item_container');
    productItem.forEach(elem => {
      elem.style.opacity = "0"
      elem.style.left = `calc(-${cabinetcontainer.offsetWidth}px + 50px)`;
    })
  }

  useEffect(() => {
    if (data.cabinet_image) {
      handleScroll();
      handleResize();
      window.addEventListener("resize", handleResize);
      document.addEventListener("scroll", handleScroll);
    }
    return () => {
      document.removeEventListener("resize", handleResize);
      document.removeEventListener("scroll", handleScroll);
  }
  })

  return (
    <div className={`product_item_container mx-auto my-3 item${data.id}`} style={{ position: "relative" }} onClick={handleClick} role="button">
      <div className="card-img-top rounded-top">
        {type === "cabinets" && (
          <div className="card-img-top-container animation mx-auto">
            <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.cabinet_image.src}`} className="w-100 pt-4 cabinet-image" alt=""></img>
            {!!data.is_coming_soon && (
              <img className="coming-soon-img-cabinets coming-soon-extra-padding" src="images/icons/coming-soon.webp" alt=""></img>
            )}
            {!!data.is_new && (
              <img className="coming-soon-img-cabinets" src="images/icons/new-cabinet.png" alt=""></img>
            )}
          </div>
        )}
        {data.slotGameImage && (
          <img src={data.slotGameImage} className="card-img-top animation" alt=""></img>
        )}
        {type === "game-mix" && (
          <div>
            <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + data.cover_image_small.src}`} className="card-img-top animation" alt=""></img>
            {!!data.is_coming_soon && (
              <img className="coming-soon-img-mixes" src="images/icons/coming-soon.webp" alt=""></img>
            )}
            {!!data.is_new && (
              <img className="coming-soon-img-mixes" src="images/icons/new.svg" alt=""></img>
            )}
          </div>
        )}
      </div>
      <div className="card-body bg-white p-2 text-start">
        <h5 className={`text-center ${type === "game-mix" ? "card-title" : ""} p-2 mb-0`}>{data.name}</h5>
        {data.platform && (
          <h5 className="card-subtitle text-center">{data.platform}</h5>
        )}
      </div>
    </div>
  )
}

export default ProductItem;