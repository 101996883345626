import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import { useState } from "react";
import GamesContainer from "../containers/Games/GamesContainer";

const Games = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="games_page">
        <img src="/images/header_images/igre.webp" className="w-100 d-none d-md-block" alt=""></img>
        <img src="/images/header_images/igre_mobile.webp" className="w-100 d-md-none" alt=""></img>
        <GamesContainer />
        <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
        />
        <Footer />
      </div>
    </>
  );
};

export default Games;
