import { Link } from "react-router-dom"
import { ReactComponent as Avatar } from "../assets/avatar.svg"

const SearchSite = () => {

  return(
    <div className="search-site-container d-flex align-items-center">
      <div className="avatar d-none d-md-block">
        <Link to="https://www.novomatic.com/en/login" target="blank">
          <Avatar />
        </Link>
      </div>
    </div>
  )
}

export default SearchSite