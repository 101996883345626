export const validateForm = (form) => {
  let isValid = true;
  form.querySelectorAll("[required]").forEach((elem) => {
    elem.classList.remove("is-invalid");
    if (!elem.value) {
      elem.classList.add("is-invalid");
      isValid = false;
    }
  });
  return isValid;
};

export const formatDate = (date) => {
  const dateSplited = date.split('-');
  const day = dateSplited[2]
  const month = dateSplited[1]
  const year = dateSplited[0]

  return `${day}.${month}.${year}.`
}
