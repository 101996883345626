import React, { useEffect, useState } from "react";
import MobileNavbar from "./Navbar/MobileNavbar";
import Navbar from "./Navbar/Navbar";
import SearchSite from "./SearchSite";

const Header = ({ offset, setIsSidebarOpened }) => {
  const [openedTab, setOpenedTab] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > (offset || 0)) {
      document.getElementById("header").classList.add("sticky");
    } else {
      document.getElementById("header").classList.remove("sticky");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <div id="header" className="header-area col-12" onMouseLeave={() => setOpenedTab(false)}>
      <SearchSite />
        <div>
        <Navbar
          openedTab={openedTab}
          setOpenedTab={setOpenedTab}
        />
        <MobileNavbar setIsSidebarOpened={setIsSidebarOpened} />
        </div>
      </div>
    </>
  );
};

export default Header;
