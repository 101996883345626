import React from "react";
import FilterControls from "../../components/Filter/FilterControls";

const WhiteGrayBackground = ({ title, subtitle, headerImage, children, handleChange, handleSelect, options, showFilters, resultNumber }) => {

  return (
    <div className="whitegray_background_container text-center">
      <img src={headerImage} alt="" className="pb-3 pb-md-5 header-img"></img>
      <div className="white_background_section">
        <div className="pb-5">
          {title && <h2 className="shadowtitle pb-5">{title}</h2>}
          <h6>{subtitle}</h6>
        </div>
        {showFilters && (
          <div className="filter_container ps-2">
            <FilterControls
              className="mx-3"
              handleChange={handleChange}
              handleSelect={handleSelect}
              options={options}
            />
            {resultNumber === 0 && (
              <p className="ps-3 text-start text-black">Nije pronadjen nijedan rezultat</p>
            )}
          </div>
        )}
      </div>
      <div className="gray_background_section pb-5">
        <div className="whitegray_background_content row pt-5 pb-5 mx-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WhiteGrayBackground;
