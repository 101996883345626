import React from "react";

const ProductCard = ({data, active}) => {

  return(
    <div className={`product_card_container row justify-content-around ${active ? 'active-tab' : ''}`}>
      <img className={`product_img mb-2 ${active ? 'mb-3' : ""}`} src={`/images/icons/${data.img}${active ? '_active' : ''}.svg`} alt=""></img>
      <div className="row card-body align-items-end">
        <h3 className="pt-2">{data.title}</h3>
      </div>
    </div>
  )
}
export default ProductCard;