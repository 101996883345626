import React, { useState } from "react";
import ReactSimplyCarousel from 'react-simply-carousel';

const Slider = ({children, responsiveProps}) => {

  const [activeSlide, setActiveSlide] = useState(0);
  return(
    <ReactSimplyCarousel className="flex-nowrap"
        containerProps={{
          style: {
            flexDirection: "initial",
            flexWrap: "nowrap", 
            width: "100%",
            userSelect: "text",
            justifyContent: "center",
            marginBottom: "30px"
          }
        }}
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: <div className="slider-chevron right"></div>,
          style: {
            padding: 0,
            width: 30,
            height: 30,
            alignSelf: "center",
            border: "none",
            borderRadius:"50%"
          }
        }}
        backwardBtnProps={{
          children: <div className="slider-chevron"></div>,
          style: {
            padding: 0,
            width: 30,
            height: 30,
            alignSelf: "center",
            border: "none",
            borderRadius:"50%",
            transform: "rotate(180deg)"
          }
        }}
        responsiveProps={responsiveProps}
        speed={400}
        showControls={true}
        hideNavIfAllVisible={true}
      >
        {children}
    </ReactSimplyCarousel>
  )
}

export default Slider;