import Modal from 'react-bootstrap/Modal';

const VideoModal = ({ showModal, setShowModal, data, setVideoLink }) => {

  return (
    <>
      <Modal show={showModal} centered={true} onHide={() => { setShowModal(false); setVideoLink(null) }} size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          <video
            width="100%"
            autoPlay={true}
            src={data}
            type="video/mp4"
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VideoModal