import { useEffect, useState } from "react"
import ImageGallery from "react-image-gallery"
import { api } from "../../services";


const GalleryContainer = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [filteredGallery, setFilteredGallery] = useState([]);
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [startIndex, setStartIndex] = useState();
  const [startOffset, setStartOffset] = useState(0);
  const [data, setData] = useState([])
  const itemsToLoad = 9;
  const endOffset = startOffset + itemsToLoad;
  const [categories, setCategories] = useState([]);

  const loadMore = () => {
    setStartOffset(prevOffset => prevOffset + itemsToLoad)
  }

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  const selectTag = (event) => {
    const tagName = event.target.innerHTML.toUpperCase();
    if (tagName === "PRIKAŽI SVE") setFilteredGallery(galleryData);
    else {
      const filteredGallery = galleryData.filter(g => (g.categories[0] && g.categories[0].title.toUpperCase()) === tagName)
      setFilteredGallery(filteredGallery);
    }
  }

  useEffect(() => {
    const gallery = document.querySelector('.image-gallery-slides');
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');
    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  }, [galleryData])

  useEffect(() => {
    api.gallery.getActiveImages().then(res => {
      if (!res) return;
      setGalleryData(res);
      setFilteredGallery(res);
    })

    api.categories.getGalleryCategories().then(res => {
      if (!res) return;
      setCategories(res)
    })
  }, [])

  useEffect(() => {
    setData(filteredGallery.slice(0, endOffset));
  }, [filteredGallery, startOffset])

  return (
    <>
      <img src="/images/header_images/galerija.webp" className="w-100 d-none d-md-block" alt=""></img>
      <img src="/images/header_images/galerija_mobile.webp" className="w-100 d-md-none" alt=""></img>
      <div className="pt-5 row justify-content-center w-100 m-0">
        <h2 className="shadowtitle px-2">GALERIJA</h2>
      </div>
      <div>
        <div className="row g-0 mb-5">
          <div className="col-1 col-md-2"></div>
          <div className="col-10 col-md-8">
            <div className="row justify-content-center">
              {data.map((item, index) => (
                <div key={index} className="col-4 col-md-4 mb-4">
                  <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}`} id={index} alt="" className="w-100" role="button" onClick={(event, index) => openGallery(event, index)}></img>
                </div>
              ))}
              {!filteredGallery.length && (
                <p className="no-data text-center">Nema slika u ovoj kategoriji</p>
              )}
              <div className={`btn_load_more hover_btn_load_more btn_large w-auto px-4 mt-5 mb-5 ${data.length === filteredGallery.length ? "d-none" : ""}`} onClick={loadMore}>UČITAJ VIŠE</div>
            </div>
          </div>
          <div className="col-12 col-lg-2">
            <div className="text-black mb-5 text-center categories">
              <h5 className="text-decoration-underline">KATEGORIJE</h5>
              {categories.map((item, key) => (
                <div name={item.title} onClick={selectTag} role="button" className="mx-auto" key={key}>{item.title}</div>
              ))}
              <div onClick={selectTag} role="button" className="mx-auto mt-3">Prikaži sve</div>
            </div>
          </div>
        </div>
      </div>
      <div className='gallery_container'>
        <ImageGallery
          items={filteredGallery.map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.original}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
          startIndex={startIndex}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>
      <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
    </>
  )
}
export default GalleryContainer