import WhiteGrayBackground from "../containers/BackgroundContainer/WhiteGrayBackground";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import Sidebar from "../components/Navbar/Sidebar";
import { useEffect, useState } from "react";
import Slider from "../components/Slider/Slider";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery"
import { api } from "../services";
import { formatDate } from "../utils/validation";

const NewsDetails = () => {
  const responsive = [
    {
      itemsToShow: 1,
      maxWidth: 600,
    },
    {
      itemsToShow: 2,
      minWidth: 600,
    },
    {
      itemsToShow: 2,
      minWidth: 1200,
    },
    {
      itemsToShow: 3,
      minWidth: 1500,
    }
  ]

  const { id } = useParams();
  const [allNews, setAllNews] = useState([]);
  const [news, setNews] = useState(null);
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  const [videoHref, setVideoHref] = useState();
  const [showVideo, setShowVideo] = useState(false)
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [startIndex, setStartIndex] = useState();

  const handleVideo = (event) => {
    const el = event.target;
    event.preventDefault();
    setVideoHref(el.href)
    setShowVideo(true);
  }

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  useEffect(() => {
    api.news.getAllNews().then(res => {
      if (!res) return;
      setAllNews(res);
    })
    api.news.getNewsById(id).then(res => {
      if (!res) return;
      setNews(res);
    })
  }, [])

  useEffect(() => {
    document.querySelectorAll('.news_detail_content p a').forEach(item => {
      if (!item.href.includes('youtube')) return;
      item.addEventListener('click', handleVideo)
    })
    return () => {
      document.removeEventListener("click", handleVideo);
    };
  }, [news])

  useEffect(() => {
    const gallery = document.querySelector('.image-gallery-slides');
    if (!gallery) return;
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');

    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  })

  return (
    <>
      {news && (
        <>
          <Header setIsSidebarOpened={setIsSidebarOpened} />
          <div className="news_details_page">
            <div className="news_detail_container">
              <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + news['cover_image'].src}`} className="w-100 d-none d-md-block" alt=""></img>
              <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + news['cover_image_small'].src}`} className="w-100 d-md-none" alt=""></img>
              <WhiteGrayBackground title={news.title} subtitle={news.subtitle}>
                <div className="news_detail_content bg-white p-4 mx-auto rounded text-start mb-5">
                  {news.media && !!news.media.length && (
                    <Slider items={3} responsiveProps={responsive}>
                      {news.media.map((item, key) => {
                        return (
                          <div
                            style={{
                              border: "12px solid transparent",
                              textAlign: "center",
                              boxSizing: "border-box"
                            }}
                            key={key}
                          >
                            <div className="col-6 about_item_container mb-4" style={{ width: "16.5rem" }}>
                              <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}`} id={key} className="card-img-top w-100" alt="" role="button" onClick={(event, index) => openGallery(event, index)}></img>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  )}
                  <div className="news_detail_date date">{formatDate(news.date)}</div>
                  <h5 className="mb-5">{news.subtitle}</h5>
                  <p className="text-start" dangerouslySetInnerHTML={{ __html: news.description }}></p>
                </div>
                <div className="mx-auto">
                  <h5 className="text-black text-decoration-underline mb-5" style={{ fontFamily: 'Lato' }}>POVEZANO</h5>
                  <div className="row justify-content-center">
                    {allNews.filter(item => item.id !== news.id).slice(0, 3).map((item, key) => (
                      <div className="col-12 col-sm-4 text-start text-black mb-4" style={{ fontFamily: "Lato" }} key={key}>
                        <a href={`/novosti-detalji/${item.id}`} className="text-decoration-none">
                          {item.new && (
                            <img src="/images/icons/new.svg" alt="" className="new_tag"></img>
                          )}
                          {item.comingSoon && (
                            <img src="/images/icons/coming-soon.webp" alt="" className="new_tag p-1"></img>
                          )}
                          <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} alt="" className="mb-3 w-100"></img>
                          <div className="text news_title pb-3">{item.title.toUpperCase()}</div>
                          <div className="mt-1" style={{ color: "#3666AE", fontSize: "12px" }}>{formatDate(item.date)}</div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </WhiteGrayBackground>
              {showVideo && (
                <div className="youtube_video">
                  <iframe width="100%" height="100%" src={videoHref} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              )}
              <div onClick={() => setShowVideo(false)} className={`${showVideo ? "fullOverlay" : ""}`}></div>
              <Sidebar
                isSidebarOpened={isSidebarOpened}
                setIsSidebarOpened={setIsSidebarOpened}
              />
              {!!news.media && (
                <ImageGallery
                  items={(news.media || []).map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
                  startIndex={startIndex}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              )}
              <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default NewsDetails;