import { useParams } from "react-router";
import WhiteBackground from "../BackgroundContainer/WhiteBackground"
import { useEffect, useState } from "react";
import Login from "../../components/Login/Login";
import VideoModal from "../../components/Slider/VideoModal";
import { api } from "../../services"
import ReactImageGallery from "react-image-gallery";
import GallerySlider from "../../components/Slider/GallerySlider";

const headlines = {
  facts: "Činjenice igre",
  dimensions: "Tehničke karakteristike",
  mixes: "Sve igre u ovom GAME MIX-u",
  mixesButton: "Prikaži sve",
  login: {
    title: "User login",
    button: "Log in",
    links: [
      { title: "Kreiraj nov nalog", href: "https://www.novomatic.com/en/register" },
      { title: "Resetuj lozinku", href: "https://www.novomatic.com/en/password" }
    ],
  }
};

const GameMixDetailsContainer = () => {
  const { id } = useParams();
  const [gameMix, setGameMix] = useState(null);
  const [showModal, setShowModal] = useState('');
  const [showGamesModal, setShowGamesModal] = useState('');
  const [videoLink, setVideoLink] = useState(null);
  const [startIndex, setStartIndex] = useState();
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  useEffect(() => {
    if(!gameMix) return;
    const gallery = document.querySelector('.image-gallery-slides');
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');
    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  }, [gameMix])

  useEffect(() => {
    api.gameMixes.getGameMixById(id).then(res => {
      if (!res) return;
      setGameMix(res)
    })
  }, [])

  return (
    <>
      {gameMix && (
        <div className="mixes">
          <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + gameMix.cover_image.src}`} className="w-100 d-none d-md-block" alt=""></img>
          <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + gameMix.cover_image_small.src}`} className="w-100 d-md-none" alt=""></img>
          <WhiteBackground title={gameMix.name} noUppercase>
            <div className="row mt-5">
              <div className="inner col-12 col-xl-8">
                <img className="w-100" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + gameMix.cover_image_small.src}`} alt=""></img>
                <div className="row">
                  {(gameMix.video_links || []).map((item, key) => (
                    <div className="col-4 col-md-2 gx-4 mt-3" role="button" onClick={() => { setVideoLink(item); setShowModal(true); }} key={key}>
                      <img className="w-100" src={`/images/icons/play.webp`} alt="" />
                    </div>
                  ))}
                  {gameMix.media.map((item, index) => (
                    <div className="col-4 col-md-2 gx-4 mt-3" key={index}>
                      <div role="button" onClick={(event, index) => openGallery(event, index)}>
                        <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}`} id={index} alt="" className="w-100" role="button" onClick={(event, index) => openGallery(event, index)}></img>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="cabinet_bottom py-5">
                  <div className="descr_left text-start">
                    <div className="row align-items-center">
                      <div className="col-auto">Platforma</div>
                      <div className="col">
                        <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + gameMix.platforms[0].media.src}`} alt="" className="game-mix-platform"></img>
                      </div>
                    </div>
                    <h2 className="text-start py-5">{gameMix.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html: gameMix.description }}></p>
                    <div className="games_all w-100 pb-4">
                      <h4 className="p-4">Igre u Game Mix-u</h4>
                      <div className="row p-4">
                        {gameMix.games.slice(0, 4).map((item, key) => (
                          <div className="col-12 col-sm-6" key={key}>
                            <a href={item.href} target="blank" className="text-decoration-none">
                              <div className="d-flex">
                                <div>
                                  <img className="rounded m-2" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.media.src}`} alt=""></img>
                                </div>
                                <div className="text-black m-2">{item.name}</div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                      <div className="btn_load_more hover_btn_load_more ms-4 text-uppercase" onClick={() => setShowGamesModal("games")}>{headlines.mixesButton}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-xl-4 text-start mb-3">
                <div className="row gx-0">
                  <div className="cabinets_all border col-12 col-md-6 col-xl-12 p-4 mb-4">
                    <h4 className="pb-3">Cabinets</h4>
                    {gameMix.cabinets.map((item, key) => {
                      return (
                        <div key={key}>
                          <div className="p-2 w-100">
                            <a href={`/automati-detalji/${item.id}`} className="text-black text-decoration-none">{item.name}</a>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="col-12 col-sm-6 col-xl-12 px-0">
                    <Login data={headlines} />
                  </div>
                </div>
              </div>
            </div>
          </WhiteBackground>
          {videoLink && (
            <VideoModal
              showModal={showModal}
              setShowModal={setShowModal}
              setVideoLink={setVideoLink}
              data={videoLink}
            />
          )}
          <div className='gallery_container'>
            <ReactImageGallery
              items={(gameMix.media || []).map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
              startIndex={startIndex}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
          </div>
          <GallerySlider 
            showModal={showGamesModal} 
            setShowModal={setShowGamesModal} 
            data={gameMix} 
            headlines={headlines}
            // imgPath={`/images/mixes/${gameMix.id}/`}
            />
        </div>
      )}
    </>
  )
}
export default GameMixDetailsContainer