import React from "react";
import GrayDotsBackground from "../../containers/BackgroundContainer/GrayDotsBackground";
import Animation from "../Animations/Animation";

import { useInView } from 'react-intersection-observer';

const Facts = () => {
  const [ref, inView] = useInView({
    threshold: 0
  });
  const [ref2, inView2] = useInView({
    threshold: 0
  });
  const [ref3, inView3] = useInView({
    threshold: 0
  });
  const [ref4, inView4] = useInView({
    threshold: 0
  });

  return (
    <GrayDotsBackground title="ČINJENICE I BROJKE">
      <div className="row justify-content-center g-0 px-3 counter_container mt-0">
        <div className="d-none d-md-block" ref={ref}>
          <Animation
            riveSrc="/animations/novomatic_brojke_preko.riv"
            inView={inView}
          />
        </div>
        <div className="d-md-none" ref={ref2}>
          <Animation
            riveSrc="/animations/novomatic_brojke_mob_1.riv"
            inView={inView2}
          />
        </div>
        <div className="d-md-none" ref={ref3}>
          <Animation
            riveSrc="/animations/novomatic_brojke_mob_2.riv"
            inView={inView3}
          />
        </div>
        <div className="d-md-none" ref={ref4}>
          <Animation
            riveSrc="/animations/novomatic_brojke_mob_3.riv"
            inView={inView4}
          />
        </div>
      </div>
    </GrayDotsBackground>
  )
}

export default Facts