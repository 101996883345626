export const createCategoriesApi = (fetchApi) => {

  function getGalleryCategories() {
    return fetchApi(`/categories/gallery`, 'GET');
  }

  function getNewsCategories() {
    return fetchApi(`/categories/news`, 'GET');
  }

  function getNewsTags() {
    return fetchApi(`/tags`, 'GET');
  }

  return {
    getGalleryCategories,
    getNewsCategories,
    getNewsTags
  };
};