import React from "react";

const WhiteBackground = ({ title, headerImage, children }) => {
  return (
    <div className="clean_white_background_container">
      {headerImage && <img src={headerImage} alt="" className="header-img"></img>}
      <div className="clean_white_background_section">
        {title && 
          <div className="pt-5 row justify-content-center w-100 m-0">
            <h2 className="shadowtitle px-2">{title}</h2>
          </div>
        }
        <div className="row justify-content-around ms-0 w-100">{children}</div>
      </div>
    </div>
  );
};

export default WhiteBackground;
