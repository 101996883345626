export const createGameMixesApi = (fetchApi) => {
  
  // function getGameMixes() {
  //   return fetchApi(`/p/game-mixes`, 'GET');
  // }

  // function getGameMixById(id) {
  //   return fetchApi(`/p/game-mix/${id}`, 'GET');
  // }

  function getGameMixes() {
    return fetchApi(`/game-mixes`, 'GET');
  }

  function getGameMixById(id) {
    return fetchApi(`/game-mix/${id}`, 'GET');
  }

  return {
    getGameMixes,
    getGameMixById
  };
};