import Modal from 'react-bootstrap/Modal';

const GallerySlider = ({showModal, setShowModal, data, headlines}) => {
  return(
    <>
      <Modal show={showModal} centered={true} onHide={() => setShowModal('')} size="xl">
        <Modal.Header closeButton />
        <Modal.Body>
          {showModal === 'games' && (  
            <div className="p-4 row m-2 grayBackground">
              <h4 className='text-center'>{headlines.mixes}</h4>
              {data.games.map((item, key) => (
                <div key={key} className="p-3 col-12 col-lg-6">
                  <a href={item.href} target='blank' className="text-decoration-none">
                    <div className="d-flex all_mixes">
                      <div>
                        <img className="rounded w-auto m-2" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.media.src}`} alt=""></img>
                      </div>
                      <div className="text-black m-2">{item.name}</div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )}
          {showModal === 'mixes' && (  
            <div className="p-4 row m-2 grayBackground">
              <h4 className='text-center'>{headlines.mixes}</h4>
              {data.gameMixes.map((item, key) => (
                <div key={key} className="p-3 col-12 col-lg-6">
                  <a href={`/mix-igara-detalji/${item.id}`} className="text-decoration-none">
                    <div className="d-flex all_mixes">
                      <div>
                        <img className="rounded m-2" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} alt="" style={{width: "100px"}}></img>
                      </div>
                      <div className="text-black m-2">{item.name}</div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GallerySlider