import React, { useEffect, useRef } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Novomatic-logo.svg";
import menuItems from "../../data/NavbarData.json"
import { ReactComponent as Avatar } from "../../assets/avatar.svg"

const MobileNavbar = ({setIsSidebarOpened}) => {
  const ref = useRef(null);
  const [items, setItems] = useState(menuItems);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDocumentClick = (event) => {
    if (!ref.current) return;
    if (!ref.current.contains(event.target)) closeMenu();
  }

  const openMenu = () => {
    ref.current.querySelector('.mobile-navbar-menu').style.width = "70vw";
    setItems(menuItems);
  }

  const closeMenu = () => {
    ref.current.querySelector('.mobile-navbar-menu').style.width = "0";
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    }
  }, []);  

  const handleLinkChange = (event, item) => {
    event.stopPropagation();
    if (item.href) {
      if(item.href.startsWith('http')){
        window.open(item.href, '_blank');
      }
      else{
        navigate(item.href);
      }
      
    } else {
      setItems(item.links);
    }
  }

  const getPrevTitle = (items) => {
    const firstId = String(items[0].id);
    const id = firstId.slice(0, -1);
    if (id.length === 1) return menuItems.find(item => item.id === Number(id)).title;
    return menuItems.find(item => item.id === Number(id.slice(0,1))).links.find(item => item.id === Number(id)).title;
  }

  const backButtonExists = (items) => {
    return String(items[0].id).length > 1;
  }

  const handleBackLink = (event, items) => {
    event.stopPropagation();
    const firstId = String(items[0].id);
    const id = firstId.slice(0, -2);
    if (!id) setItems(menuItems);
    else setItems(menuItems.find(item => item.id === Number(id)).links)
  }

  const handleSidebar = () => {
    ref.current.querySelector('.mobile-navbar-menu').style.width = "0";
    setIsSidebarOpened(true);
  }

  return(
    <div className="mobile-navbar text-center d-md-none" ref={ref}>
      <div className="row col-12 mx-0 py-2">
        <div className="col-2 my-auto px-0">
          <i className="fa fa-bars" role="button" onClick={openMenu}></i>
        </div>
        <div className="col-8 p-0">
          <Link to="/">
            <Logo className="w-50"/>
          </Link>
        </div>
      </div>
      {location.pathname === "/" && 
        <div className="line rotate_180"></div>
      }
      <div className="mobile-navbar-menu text-center">
        <div  className="mobileMenu">
          <div className="mobileMenu_header row justify-content-between gx-0 p-0">
            <div className="mobile_header_card col-4 bg-white">
              <Link className="imgLink" to="/">
                <img className="vertical_Y_align" src="/images/logo/N-logo.svg" alt=""></img>
              </Link>
            </div>
            <Link className="imgLink col-4" to="/kontakt">
              <div className="mobile_header_card mobileMenu_header_item">
                <div className="vertical_Y_align">
                  <img src="/images/icons/envelope.svg" alt=""></img>
                  <p>KONTAKT</p>
                </div>
              </div>
            </Link>
            <div className="mobile_header_card col-4 mobileMenu_header_item" onClick={handleSidebar} role="button">
                <div className="vertical_Y_align">
                <img src="/images/icons/star.svg" alt=""></img>
                  <p>AUTOMATI</p>
                </div>
            </div>
            <div className="col-3 bg-white" onClick={closeMenu}>
            </div>
          </div>
          <div>
          { backButtonExists(items) && (
                <div className="previous_tab previous_tab d-flex align-items-center" role="button">
                  <i className="fa fa fa-chevron-left" onClick={(event) => handleBackLink(event, items)}></i>
                  <div>{getPrevTitle(items)}</div>
                </div>
              )}
            <ul className="menu_list text-start p-0 m-0">
              {[...items].sort((a,b) => {return a.mobileOrder - b.mobileOrder}).map((item, key) => (
                <Fragment key={item.id}>
                  <div key={key} onClick={(event) => handleLinkChange(event, item)} role="button">
                    <div className={`mobileMenu_list_item d-flex justify-content-between align-items-center ${!item.links ? "py-2" : ""}`}>
                      <li className={`ps-3 ${!item.links ? "p-0" : ""}`}>{item.title}</li>
                      {item.links && (
                        <div className="chevron_container">
                          <div className="chevron" onClick={(event) => {event.stopPropagation(); setItems(item.links)}}>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
              <div className="pb-2">
                <li className="ps-3">
                  <Link to="https://www.novomatic.com/en/login" target="blank">
                    <Avatar />
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MobileNavbar;