import React from "react";

const AddressCard = ({data}) => {
  return(
    <div className="text-center p-0">
      <div className="contact-address m-2 py-4 bg-white shadow-sm">
        <p>Tehnička podrška NOVO INVESTMENT</p>
        <p>{data.address}</p>
        <p>
          <b>{data.email}</b>
        </p>
        <p className="p-1">
          Radno vreme: radnim danima od <b>08:00 - 16:00</b>
        </p>
      </div>
    </div>
  )
}

export default AddressCard;