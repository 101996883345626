import WhiteGrayBackground from "../../containers/BackgroundContainer/WhiteGrayBackground"
import img1 from "../../assets/career1-1.webp";
import img2 from "../../assets/career2-1.webp";
import img3 from "../../assets/career3-1.webp";
import img5 from "../../assets/career4-1.webp";
import img6 from "../../assets/career5-1.webp";

const CareerNovomatic = () => {

  return(
    <>
      <img src="/images/header_images/karijere.webp" className="w-100 d-none d-md-block" alt=""></img>
      <img src="/images/header_images/karijere_mobile.webp" className="w-100 d-md-none" alt=""></img>
      <WhiteGrayBackground title={"KARIJERE"}>
      <div className="container about">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 pe-md-4">
            <div className="about_tab bg-white radius-4 blue_text">
              <div className="about_tab_header text-uppercase text-center">NAŠI ZAPOSLENI</div>
              <div className="p-4 text-start">
              Naši zaposleni su naša najvrednija imovina. Više od 40 godina smo u prilici da pišemo međunarodne uspešne priče zahvaljujući njihovim veštinama, posvećenosti i odlučnosti. Kao jedna od vodećih produkcijskih i uslužnih kompanija u industriji tehnologije i igara, uvek smo na osmatračnicama talentovanih ljudi koji žele da inoviraju i oblikuju budućnost kod nas. Našim zaposlenima nudimo kreativno, bezbedno radno okruženje, uzbudljive zadatke i mogućnost daljeg razvoja znanja i veština.
              </div>
            </div>
            <div className="pb-1 pb-xl-0 m-auto">
              <img src={img2} alt="" className="radius-4 my-4 my-md-5"></img>
            </div>
            <div className="about_tab bg-white radius-4 blue_text">
              <div className="about_tab_header text-uppercase text-center">Ljudi u NOVOMATIC-u</div>
              <div className="p-4 text-start">
              Uvek tražimo prave ljude, sa idejom, nekoga ko će nam se pridružiti na ovom globalnom putovanju i postati deo pobedničkog tima.
              <br/><br/>
              Veoma dobro razumemo da uspeh zavisi od zajedničkog rada i da je kvalitet grupe determinisan osobinama pojedinaca. Potrebni su nam hrabri i kreativni ljudi, sposobni da oblikuju kako svoju, tako i budućnost kompanije. Ako osećate da možete da nam pomognete i imate potrebne kvalitete da postanete deo NOVOMATIC kompanije, pružićemo vam priliku i motivaciju za uspeh. Priključite nam se kako bismo gradili velike stvari!
              </div>
            </div>
            <div className="pb-xl-0 m-auto">
              <img src={img5} alt="" className="radius-4 my-4 my-md-5"></img>
            </div>
            <div className="about_tab bg-white radius-4 blue_text">
              <div className="about_tab_header text-uppercase text-center">Vaša prijava</div>
              <div className="p-4 text-start">
              Da li smo izazvali vaše interesovanje? Ukoliko biste želeli da budete deo tehnološke kompanije, da radite na uzbudljivim projektima i nastavite sa nama da pišete priču o uspehu NOVOMATIC-a, <a className="text-black text-decoration-none" href="https://poslovi.infostud.com/poslodavac/novomatic-srbija/66347?#oglasi" target="blank"><b>pogledajte trenutno aktivne oglase.</b></a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 ps-md-4">
            <div className="pb-xl-0 m-auto">
              <img src={img1} alt="" className="radius-4 my-4 my-md-5"></img>
            </div>
            <div className="about_tab bg-white radius-4 blue_text">
              <div className="about_tab_header text-uppercase text-center">NOVOMATIC kao poslodavac</div>
              <div className="p-4 text-start">
              NOVOMATIC AG osnovan je u Austriji 1980. godine i danas predstavlja evropskog lidera i jednu od najvećih svetskih kompanija u oblasti tehnologije igara na sreću. Danas posluje u preko 50 zemalja širom sveta i zapošljava više od 23.000 ljudi, a u Srbiji NOVOMATIC je prisutan već 20 godina i trenutno naš tim čini preko 2.000 zaposlenih.
              </div>
            </div>
            <div className="pb-xl-0 m-auto">
              <img src={img3} alt="" className="radius-4 my-4 my-md-5"></img>
            </div>
            <div className="about_tab bg-white radius-4 blue_text">
              <div className="about_tab_header text-uppercase text-center">MI SMO NOVOMATIC</div>
              <div className="p-4 text-start">
              Kompaniju ne čine samo brojke i rezultati. Najvažniji činioci uspeha su naši ljudi. Pod motom "MI SMO NOVOMATIC SRBIJA" stavljamo zaposlene u fokus podržavajući njihove aktivnosti u kompaniji i usmeravajući ih ka konstantnom napredovanju i usavršavanju. <a className="text-black text-decoration-none" href="https://poslovi.infostud.com/poslodavac/novomatic-srbija/66347?#o-kompaniji" target="blank"><b>Saznajte više o Novomatic-u kao poslodavcu.</b></a>
              </div>
            </div>
            <div className="pb-xl-0 m-auto">
              <img src={img6} alt="" className="radius-4 my-4 my-md-5"></img>
            </div>
          </div>
        </div>
        <div className="career-line">
          <div className="line"></div>
          <div className="bullet-point"></div>
          <div className="bullet-point"></div>
          <div className="bullet-point"></div>
          <div className="bullet-point"></div>
          <div className="bullet-point"></div>
          <div className="bullet-point"></div>
        </div>
      </div>
    </WhiteGrayBackground>
    </>
  )
}
export default CareerNovomatic