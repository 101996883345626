import React, { useEffect, useState } from "react";
import WhiteDotsBackground from "../BackgroundContainer/WhiteDotsBackground";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/validation";
import { api } from "../../services";

const NewsContainer = () => {
  const [news, setNews] = useState();

  useEffect(() => {
    api.news.getAllNews().then(res => {
      if (!res) return;
      setNews(res);

    })
  }, [])

  return (
    <WhiteDotsBackground title={"NOVOSTI"} button="UČITAJ SVE">
      <div className="new d-flex align-items-center mb-4">
        <div className="new-tag mx-3">NOVO</div>
        <div className="text-start text-black">Predstojeći događaji i vesti o Novomaticu</div>
      </div>
      {news && (
        <div className="row g-0 text-start home-news">
          <div className="col-12 col-md-6 first-news">
            <Link className="text-decoration-none" to={`/novosti-detalji/${news[0].id}`}>
              <div className="first-news-container">
                {!!news[0].is_new && (
                  <img src="/images/icons/new.svg" alt="" className="new-icon"></img>
                )}
                {!!news[0].is_coming_soon && (
                  <img src="/images/icons/coming-soon.webp" alt="" className="coming-soon-icon"></img>
                )}
                <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + news[0].cover_image_small.src}`} alt="" className="w-100"></img>
                <div className="row g-0 justify-content-between description w-100">
                  <div className="tag">
                    <div>{news[0].tag}</div>
                  </div>
                  <div className="title">{news[0].title.toUpperCase()}</div>
                  <div className="date">{formatDate(news[0].date)}</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 home-other-news">
            <div className="ms-md-3 mt-2 mt-md-0">
              {news.slice(1, 4).map((item, key) => (
                <Link to={`/novosti-detalji/${item.id}`} className="text-decoration-none" key={key}>
                  <div className={`news-container row g-0 ${key > 0 ? "mt-1" : ""}`}>
                    {!!item.is_new && (
                      <img src="/images/icons/new.svg" alt="" className="new-icon"></img>
                    )}
                    {!!item.is_coming_soon && (
                      <img src="/images/icons/coming-soon.webp" alt="" className="coming-soon-icon"></img>
                    )}
                    <div className="cover-image col-6 col-sm-4">
                      <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} className="w-100 h-auto mb-2" alt=""></img>
                      <div className="tag">{(item.tags[0] || {}).title}</div>
                    </div>
                    <div className="col-6 col-sm-8">
                      <div className="info row g-0 justify-content-between">
                        <div className="title title-news">{item.title.toUpperCase()}</div>
                        <div className="row g-0 align-items-end">
                          <div className="date">{formatDate(item.date)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="news-btn mt-5">
        <Link to="/novosti" className="btn_load_more hover_btn_load_more btn_large w-auto px-4 mb-4 mx-auto">UČITAJ SVE</Link>
      </div>
    </WhiteDotsBackground>
  )
}

export default NewsContainer;