import React from "react";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import { useState } from "react";
import ContactForm from "../components/Contact/ContactForm";
import AddressCard from "../components/Contact/AddressCard";

const data = {
  address: "Japanska 3a, 11000 Beograd, Republika Srbija",
  email: "service@novoinvestment.com",
  label: "Za sve informacije ili probleme sa našim proizvodima možete da kontaktirate naš tim za podršku.",
  name: "Ime i prezime",
  phone: "Telefon",
  company: "Firma",
  serial: "Serijski broj automata",
  message: "Poruka"
}

const Support = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="support_page">
        <img src="/images/header_images/podrska.webp" className="w-100 d-none d-md-block" alt=""></img>
        <img src="/images/header_images/podrska_mobile.webp" className="w-100 d-md-none" alt=""></img>
        <div className="contact-page-container">
          <div className="contact-page-section">
              <div>
                <div>
                  <h2 className="shadowtitle text-black">PODRŠKA</h2>
                </div>
              </div>
            <div className="row justify-content-between m-0">
              <div className="row m-0 support-container pt-0">
              <AddressCard data={data}/>
              <ContactForm data={data} />
            </div>
            </div>
          </div>
        </div>
        <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
        />
        <Footer />
        <Scrolltop />
      </div>
    </>
  );
};

export default Support;
