import React, { useState } from "react";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import ContactContainer from "../containers/Contact/ContactContainer";
import NovomaticMap from "../components/Map/NovomaticMap";

const Contact = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="contact_page">
        <NovomaticMap
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmf-fWBlUVM01O7PyswNlx3odWvapCBV0&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="google-map-container" style={{ height: `607px`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, borderRadius: "10px" }} />}
        >
        </NovomaticMap>
        <ContactContainer />
        <Sidebar
          isSidebarOpened={isSidebarOpened}
          setIsSidebarOpened={setIsSidebarOpened}
        />
        <Footer />
        <Scrolltop />
      </div>
    </>
  );
};

export default Contact;
