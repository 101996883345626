import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import CabinetDetailsContainer from "../containers/Cabinets/CabinetsDetailsContainer";

const CabinetDetails = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return(
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="cabinets_details_page">
        <CabinetDetailsContainer />
        <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
          />
        <Footer />
      </div>
    </>
  )
}

export default CabinetDetails;