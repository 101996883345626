import React from "react";

const WhiteDotsBackground = ({title, subtitle, headerImage, children, noUppercase}) => {
  return (
    <div className="white_background_container">
      <img src={headerImage} alt="" className="header-img pb-md-5"></img>
      <div className="white_background_section">
        <div className="pb-md-2">
          {title && (
            <h2 className={`text-center ${noUppercase ? '' : 'text-uppercase'} shadowtitle text-black`}>{title}</h2>
          )}
          <h6>{subtitle}</h6>
        </div>
        <div className="col-12 m-0 p-2">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WhiteDotsBackground;