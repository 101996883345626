
import React from "react";
import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import GameMixDetailsContainer from "../containers/GameMix/GameMixDetailsContainer";

const GameMixDetails = () => {

  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="game_mix_details_page">
        <GameMixDetailsContainer /> 
        <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
        />
        <Footer />
      </div>
    </>
  );
};

export default GameMixDetails;