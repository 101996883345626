import GrayBackground from "../BackgroundContainer/GrayBackground";
import { useEffect, useState } from "react";
import CabinetRowContainer from "./CabinetRowContainer";
import { api } from "../../services";

const CabinetsContainer = () => {
  const device = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ? "mobile" : "desktop";
  const [startOffset, setStartOffset] = useState(0);
  const itemsToLoad = device === "mobile" ? 8 : 2;
  const endOffset = startOffset + itemsToLoad;
  const [cabinetsFormated, setCabinetsFormated] = useState();
  const [data, setData] = useState();

  const loadMore = () => {
    setStartOffset(prevOffset => prevOffset + itemsToLoad);
  }

  useEffect(() => {
    api.cabinets.getCabinets().then(res => { 
      if (!res) return;
      setCabinetsFormated(Array.from({ length: Math.ceil(res.length / (device === "mobile" ? 1 : 4)) }, (_, index) => res.slice(index * (device === "mobile" ? 1 : 4), (index + 1) * (device === "mobile" ? 1 : 4))))
    })
  }, [])

  useEffect(() => {
    if(!cabinetsFormated) return;
    setData(cabinetsFormated.slice(0, endOffset));
  }, [cabinetsFormated, startOffset])

  return (
    <>
      {cabinetsFormated && (
        <div className="cabinets-container">
          <img src="/images/header_images/automati.webp" className="w-100 d-none d-md-block" alt=""></img>
          <img src="/images/header_images/automati_mobile.webp" className="w-100 d-md-none" alt=""></img>
          <GrayBackground title={"AUTOMATI"}>
            {(data || []).map((item, index) => (
              <CabinetRowContainer data={item} key={index} />
            ))}
          </GrayBackground>
          <div className={`btn_load_more hover_btn_load_more btn_large w-auto px-4 mb-4 mx-auto ${(data || []).length === (cabinetsFormated || []).length ? "d-none" : ""}`} onClick={loadMore}>UČITAJ VIŠE</div>
        </div>
      )}
    </>
  )
}
export default CabinetsContainer