import { Link } from "react-router-dom";
import GrayDotsBackground from "../../containers/BackgroundContainer/GrayDotsBackground";


const items = [
  {
    image: "/images/about/about1.webp",
    title: "KARIJERE",
    route: "/karijere"
  },
  {
    image: "/images/about/about2.webp",
    title: "NOVOMATIC",
    route: "/o-nama"
  }
]

const HomeAbout = () =>{
  return(
    <>
      <GrayDotsBackground>
        <div className="row mx-auto home-about-container">
          {items.map((item, key) => {
            return(
              <div className="col-4 about_item_container mb-4 mx-auto" style={{width: "20rem"}} key={key}>
                <Link to={item.route} className="text-decoration-none">
                  <div className="row">
                    <img src={item.image} className="p-0" alt=""></img>
                    <div className="title text-center py-2">{item.title}</div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </GrayDotsBackground>
    </>
  )
}

export default HomeAbout;