import { useEffect, useState } from "react";
import { useParams } from "react-router";
import WhiteBackground from "../BackgroundContainer/WhiteBackground"
import Login from "../../components/Login/Login";
import VideoModal from "../../components/Slider/VideoModal";
import Slider from "../../components/Slider/Slider";
import { Link } from "react-router-dom";
import { api } from "../../services"
import ImageGallery from "react-image-gallery"
import GallerySlider from "../../components/Slider/GallerySlider";

const headlines = {
  descr: "Opis",
  facts: "Informacije:",
  dimensions: "Tehničke karakteristike:",
  mixes: "Game Mixes/Jackpots",
  mixesButton: "Prikaži sve",
  login: {
    title: "User login",
    button: "Log in",
    links: [
      { title: "Kreiraj nov nalog", href: "https://www.novomatic.com/en/register" },
      { title: "Resetuj lozinku", href: "https://www.novomatic.com/en/password" }
    ],
  }
};

const CabinetDetailsContainer = () => {
  const [showModal, setShowModal] = useState('');
  const { id } = useParams();
  const [cabinets, setCabinets] = useState(null);
  const [cabinet, setCabinet] = useState(null);
  const [startIndex, setStartIndex] = useState();
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [videoLink, setVideoLink] = useState(null)
  // const [gameMix, setGameMix] = useState(null);
  const [showGamesModal, setShowGamesModal] = useState('');

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  useEffect(() => {
    api.cabinets.getCabinetById(id).then(res => {
      if (!res);
      setCabinet(res);
    })

    api.cabinets.getCabinets().then(res => {
      if (!res) return;
      setCabinets(res);
    })
  }, [])

  useEffect(() => {
    if(!cabinet) return;
    const gallery = document.querySelector('.image-gallery-slides');
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');
    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  }, [cabinet])

  const responsive = [
    {
      itemsToShow: 1,
      maxWidth: 600,
    },
    {
      itemsToShow: 3,
      minWidth: 600,
    },
    {
      itemsToShow: 4,
      minWidth: 1200,
    },
    {
      itemsToShow: 4,
      minWidth: 1500,
    }
  ]

  return (
    <>
      {cabinet && (
        <div className="cabinets">
          <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + cabinet.cover_image.src}`} className="w-100 d-none d-md-block" alt=""></img>
          <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + cabinet.cover_image_small.src}`} className="w-100 d-md-none" alt=""></img>
          <div className="py-4 row justify-content-center w-100 m-0">
            <h2 className="shadowtitle px-2">{cabinet.name}</h2>
          </div>
          <div>
            <div className="cabinet_teaser">
              <div className="inner p-3 p-md-0">
                <div className="cabinet_left col-12 col-md-9 row align-items-center py-md-2 g-0">
                  {(cabinet.video_links || []).map((item, key) => (
                    <div className="col-4 col-md-2 gx-4" role="button" onClick={() => { setVideoLink(item); setShowModal(true); }} key={key}>
                      <img className="w-100" src={`/images/icons/play.webp`} alt="" />
                    </div>
                  ))}
                  {(cabinet.media || []).map((item, index) => (
                    <div className="col-4 col-md-2 gx-4" key={index}>
                      <div role="button" onClick={(event, index) => openGallery(event, index)}>
                        <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}`} id={index} alt="" className="w-100" role="button" onClick={(event, index) => openGallery(event, index)}></img>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="cabinet_3d col-12 col-md-3">
                  <img  className="w-100" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + cabinet.cabinet_image.src}`} alt="" />
                </div>
              </div>
            </div>
          </div>
          <WhiteBackground noUppercase>
            <div>
              <div className="cabinet_bottom row mx-2">
                <div className="descr_left text-start col-12 col-md-12 col-lg-12 col-xl-8 pb-4 pe-md-5 g-0">
                  <h2 className="text-start py-2">{headlines.descr}</h2>
                  <p dangerouslySetInnerHTML={{ __html: cabinet.description }}></p>
                  <h5 className="mt-5">
                    {headlines.facts}
                  </h5>
                  <ul className="p-0">
                    {(cabinet.info || []).map((fact, key) => (
                      <li key={key}>
                        <i className="fa fa-angle-right blue pe-2"></i>
                        <span dangerouslySetInnerHTML={{ __html: fact }} ></span>
                      </li>
                    ))}
                  </ul>
                  {(cabinet.width || cabinet.height || cabinet.depth) && (
                    <>
                      <h5 className="mt-5 ms-1">
                        {headlines.dimensions}
                      </h5>
                      <table className="ms-1">
                        <tbody>
                          <tr>
                            <td className="border p-3">Dimenzije u mm:</td>
                            <td className="border p-3">Širina: {cabinet.width} x Visina: {cabinet.height} x Dubina: {cabinet.depth}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="descr_right col-12 col-sm-12 col-lg-12 col-xl-4 row g-0">
                  <div className="game_mix_jackpots p-4 mb-4 border text-start col-12 col-md-5 col-xl-12">
                    <div>
                      <h4>{headlines.mixes}</h4>
                      {cabinet.gameMixes.slice(0, 3).map((mix, key) => (
                        <div key={key}>
                          <a href={`/mix-igara-detalji/${mix.id}`} className="blue">
                            <div className="d-flex pt-4">
                              <img className="rounded me-4 w-25 h-50 aspect-ratio-game-mixes-cabinets" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + mix.cover_image_small.src}`} alt={`ImageMix${key + 1}`} width="75px"></img>
                              <div className="text-link" dangerouslySetInnerHTML={{ __html: mix.name }}></div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className="btn_load_more hover_btn_load_more mt-4 px-3 text-uppercase" onClick={() => setShowGamesModal("mixes")} hidden={cabinet.gameMixes.length < 4}>{headlines.mixesButton}</div>
                  </div>
                  <div className="mb-5">
                    <Login data={headlines} />
                  </div>
                </div>
                <div>
                  <div className="py-5 row justify-content-center w-100 m-0">
                    <h3 className="shadowtitle px-2">OSTALI PROIZVODI</h3>
                  </div>
                  <div className="slider-container">
                    {cabinets && (
                      <Slider responsiveProps={responsive}>
                        {(cabinets || []).filter(item => item.id !== cabinet.id).map((item, key) => {
                          return (
                            <a href={`/automati-detalji/${item.id}`} className="text-decoration-none text-black" key={key}>
                              <div
                                style={{
                                  textAlign: "center",
                                  boxSizing: "border-box",
                                  width: '230px',
                                  height: '380px'
                                }}
                                key={key}
                              >
                                <div className="w-100">
                                  <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cabinet_image.src}`} className="card-img-top" alt=""></img>
                                  <div className="card-body bg-white p-2 text-start">
                                    <h5 className={`text-center "card-title"} p-2 mb-0`}>{item.name}</h5>
                                  </div>
                                </div>
                              </div>
                            </a>
                          )
                        })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </WhiteBackground>
          {videoLink && (
            <VideoModal
              showModal={showModal}
              setShowModal={setShowModal}
              setVideoLink={setVideoLink}
              data={videoLink}
            />
          )}
          <div className='gallery_container'>
            <ImageGallery
              items={(cabinet.media || []).map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
              startIndex={startIndex}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
          </div>
          <GallerySlider 
            showModal={showGamesModal} 
            setShowModal={setShowGamesModal} 
            data={cabinet} 
            headlines={headlines}  
            />            
        </div>
      )}
    </>
  )
}
export default CabinetDetailsContainer