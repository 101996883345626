import React from "react";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import HomeAbout from "../components/About/HomeAbout";
import ProductsContainer from "../components/Products/ProductsContainer";
import NewsContainer from "../containers/News/NewsContainer";
import Facts from "../components/Facts/Facts";
import { useState } from "react";
import Sidebar from "../components/Navbar/Sidebar";
import HomeHeader from "../components/HomeHeader";

const Home = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <HomeHeader setIsSidebarOpened={setIsSidebarOpened}/>
        <div className="home_page">
          <div className="home">
            <div className="">
              <video
              className="w-100 d-block"
              src="/videos/intro.mp4"
              type="video/mp4"
              playsInline 
              autoPlay
              muted
              loop
              />                
            </div>
            <ProductsContainer />
            <Sidebar 
              isSidebarOpened={isSidebarOpened}
              setIsSidebarOpened={setIsSidebarOpened}
            />
            <Facts />
            <NewsContainer />
            <HomeAbout />
            <Footer />
            <Scrolltop />
          </div>
        </div>
    </>
  );
};

export default Home;